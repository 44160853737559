import React, { useState } from 'react';
import { File, Upload, X, Eye } from 'lucide-react';

const Documents = () => {
  const [documents, setDocuments] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState(null);

  const handleFileUpload = (event) => {
    const files = Array.from(event.target.files);
    const newDocuments = files.map(file => ({
      name: file.name,
      type: file.type,
      size: file.size,
      file: file,
      dateUploaded: new Date().toISOString()
    }));
    setDocuments(prev => [...prev, ...newDocuments]);
  };

  const handleDocumentClick = (doc) => {
    setSelectedDocument(doc);
  };

  const closePreview = () => {
    setSelectedDocument(null);
  };

  const formatFileSize = (bytes) => {
    if (bytes < 1024) return bytes + ' bytes';
    else if (bytes < 1048576) return (bytes / 1024).toFixed(1) + ' KB';
    else return (bytes / 1048576).toFixed(1) + ' MB';
  };

  return (
    <div className="bg-gray-100 min-h-screen p-8">
      <h2 className="text-3xl font-bold mb-6 text-gray-800">Property Documents</h2>
      
      <div className="mb-8">
        <label htmlFor="document-upload" className="cursor-pointer bg-blue-500 hover:bg-blue-600 text-white px-6 py-3 rounded-lg inline-flex items-center transition duration-300">
          <Upload size={20} className="mr-2" />
          Upload Documents
          <input
            id="document-upload"
            type="file"
            onChange={handleFileUpload}
            className="hidden"
            multiple
          />
        </label>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {documents.map((doc, index) => (
          <div key={index} className="bg-white rounded-lg shadow-md p-6 flex flex-col">
            <div className="flex items-center mb-3">
              <File size={24} className="mr-3 text-blue-500" />
              <span className="font-semibold text-lg truncate text-gray-700">{doc.name}</span>
            </div>
            <div className="text-sm text-gray-600 mb-2">
              Size: {formatFileSize(doc.size)}
            </div>
            <div className="text-sm text-gray-600 mb-4">
              Uploaded: {new Date(doc.dateUploaded).toLocaleDateString()}
            </div>
            <button
              onClick={() => handleDocumentClick(doc)}
              className="mt-auto bg-gray-200 hover:bg-gray-300 text-gray-800 px-4 py-2 rounded-lg flex items-center justify-center transition duration-300"
            >
              <Eye size={18} className="mr-2" />
              View Document
            </button>
          </div>
        ))}
      </div>

      {selectedDocument && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4" style={{ zIndex: 1000 }}>
          <div className="bg-white rounded-lg p-6 w-full max-w-4xl max-h-[90vh] flex flex-col">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-xl font-bold text-gray-800">{selectedDocument.name}</h3>
              <button onClick={closePreview} className="text-gray-500 hover:text-gray-700">
                <X size={24} />
              </button>
            </div>
            <div className="flex-grow overflow-auto">
              {selectedDocument.type.startsWith('image/') ? (
                <img
                  src={URL.createObjectURL(selectedDocument.file)}
                  alt={selectedDocument.name}
                  className="max-w-full h-auto"
                />
              ) : (
                <iframe
                  src={URL.createObjectURL(selectedDocument.file)}
                  title={selectedDocument.name}
                  className="w-full h-full min-h-[70vh]"
                />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Documents;