// src/components/CostChart/CostChart.js
import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import styles from './CostChart.module.css';

const CostChart = ({ data, yAxisDomain }) => {
  const buyingColor = '#8884d8';
  const rentingColor = '#82ca9d';

  return (
    <div className={styles.chartContainer}>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="year" />
          <YAxis domain={yAxisDomain} tickFormatter={(value) => `$${(value / 1000).toFixed(0)}k`} />
          <Tooltip formatter={(value) => `$${value.toLocaleString()}`} />
          <Legend />
          <Line type="monotone" dataKey="cumulativeBuyingCost" name="Buying Cost (Net)" stroke={buyingColor} />
          <Line type="monotone" dataKey="cumulativeRentingCost" name="Renting Cost" stroke={rentingColor} />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}

export default CostChart;