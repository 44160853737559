import React from 'react';
import { useNavigate } from 'react-router-dom';
import { House, Plus } from 'lucide-react';

const DashboardHomeSidebar = ({ properties, onPropertySelect }) => {
  const navigate = useNavigate();

  return (
    <>
      <li className="menu-title">
        <span>Properties</span>
      </li>
      {properties.map((property) => (
        <li key={property.id}>
          <a onClick={() => onPropertySelect(property)} className="flex items-center p-2">
            <House size={20} className="mr-2" />
            <span>{property.name}</span>
          </a>
        </li>
      ))}
      <li>
        <a 
          onClick={() => navigate('/dashboard/add-property')}
          className="flex items-center p-2 mt-4"
        >
          <Plus size={20} className="mr-2" />
          <span>Add Property</span>
        </a>
      </li>
    </>
  );
};

export default DashboardHomeSidebar;