// src/components/ResultsSummary/ResultsSummary.js
import React from 'react';
import styles from './ResultsSummary.module.css';

const ResultsSummary = ({ summary, loanTerm }) => {
  const buyingColor = '#8884d8';
  const rentingColor = '#82ca9d';

  return (
    <div 
      className={styles.card}
      style={{ backgroundColor: summary.isBetter === 'Buying' ? buyingColor : rentingColor }}
    >
      <div className={styles.cardBody}>
        <h3 className={styles.cardTitle}>
          {summary.isBetter} saves you ${summary.savings.toLocaleString()} over {loanTerm} years
        </h3>
        <div className={styles.costGrid}>
          <div>
            <p className={styles.costLabel}>Renting Cost</p>
            <p className={styles.costValue}>${summary.rent.toLocaleString()}</p>
          </div>
          <div>
            <p className={styles.costLabel}>Buying Cost (Net)</p>
            <p className={styles.costValue}>${summary.buy.toLocaleString()}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResultsSummary;