import React, { useState, useEffect } from 'react';
import { Outlet, useNavigate, Link, useLocation } from 'react-router-dom';
import { LogOut, Menu, Building } from 'lucide-react';
import { logout } from '../../utils/auth';
import DashboardHomeSidebar from '../../components/DashboardHomeSidebar';
import PropertySpecificSidebar from '../../components/PropertySpecificSidebar';
// import { supabase } from '../../utils/supabaseClient';

const Dashboard = () => {
  const [properties, setProperties] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    fetchProperties();
  }, []);

  useEffect(() => {
    const pathParts = location.pathname.split('/');
    const propertyId = pathParts[3];
    if (propertyId && properties.length > 0) {
      const property = properties.find(p => p.id.toString() === propertyId);
      setSelectedProperty(property || null);
    } else if (pathParts[2] !== 'property') {
      setSelectedProperty(null);
    }
  }, [location, properties]);

  const fetchProperties = async () => {
    // Fake data
    const fakeProperties = [
      { id: 1, name: "123 Main St" },
      { id: 2, name: "456 Elm St" },
      { id: 3, name: "789 Oak Ave" },
    ];
    setProperties(fakeProperties);
  };

  const handleLogout = async () => {
    await logout();
    navigate('/login');
  };

  const handlePropertySelect = (property) => {
    setSelectedProperty(property);
    navigate(`/dashboard/property/${property.id}`);
  };

  const handleBackToProperties = () => {
    setSelectedProperty(null);
    navigate('/dashboard');
  };

  return (
    <div className="flex flex-col h-screen">
      {/* Header - Full width, always on top */}
      <header className="bg-white shadow-sm p-4 flex justify-between items-center z-50">
        <div className="flex items-center">
          <label htmlFor="my-drawer" className="btn btn-primary drawer-button lg:hidden">
            <Menu size={24} />
          </label>
          <Link to="/dashboard" className="flex items-center space-x-2 text-blue-600 hover:text-blue-800 ml-4">
            <Building size={24} />
            <span className="text-2xl font-bold">PropManager</span>
          </Link>
        </div>
        <button 
          onClick={handleLogout} 
          className="flex items-center space-x-2 text-blue-600 hover:text-blue-800"
        >
          <LogOut size={20} />
          <span>Logout</span>
        </button>
      </header>

      {/* Drawer container */}
      <div className="drawer lg:drawer-open flex-1">
        <input id="my-drawer" type="checkbox" className="drawer-toggle" />
        
        {/* Page content */}
        <div className="drawer-content flex flex-col">
          <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-200 p-6">
            <Outlet context={{ selectedProperty }} />
          </main>
        </div> 

        {/* Sidebar content */}
        <div className="drawer-side">
          <label htmlFor="my-drawer" aria-label="close sidebar" className="drawer-overlay"></label> 
          <ul className="menu p-4 w-80 min-h-full bg-base-200 text-base-content">
            {selectedProperty ? (
              <PropertySpecificSidebar 
                property={selectedProperty} 
                onBackToProperties={handleBackToProperties} 
              />
            ) : (
              <DashboardHomeSidebar 
                properties={properties} 
                onPropertySelect={handlePropertySelect} 
              />
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;