import React, { useState, useEffect } from 'react';
import { Plus, ChevronDown, ChevronUp, Paperclip, X } from 'lucide-react';

const categories = {
  Income: ['Deposit', 'Rent Payment'],
  Expenses: [
    'Advertising', 'Supplies', 'Utilities', 'Maintenance', 'Repairs',
    'HOA', 'Management Fees', 'Legal or Accounting Charges',
    'Long Distance Travel', 'Taxes', 'Mortgage Interest', 'Mileage',
    'Insurance Premiums'
  ]
};

const Financials = () => {
  const [transactions, setTransactions] = useState([]);
  const [newTransaction, setNewTransaction] = useState({
    date: '',
    category: '',
    subCategory: '',
    amount: '',
    description: '',
    invoices: []
  });
  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    if (newTransaction.subCategory === 'Mileage' && newTransaction.description === '') {
      setNewTransaction(prev => ({
        ...prev,
        description: 'Vehicle: '
      }));
    }
  }, [newTransaction.subCategory]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewTransaction(prev => {
      const updatedTransaction = { ...prev, [name]: value };
      
      // If changing from Mileage to another category, reset description if it was the default mileage prompt
      if (name === 'subCategory' && prev.subCategory === 'Mileage' && prev.description === 'Enter vehicle make: ') {
        updatedTransaction.description = '';
      }
      
      return updatedTransaction;
    });
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    const validFiles = files.filter(file => 
      file.type === 'application/pdf' || file.type.startsWith('image/')
    );

    if (validFiles.length !== files.length) {
      alert('Some files were not added. Please upload only PDF or image files.');
    }

    setNewTransaction(prev => ({
      ...prev,
      invoices: [...prev.invoices, ...validFiles]
    }));
  };

  const removeInvoice = (indexToRemove) => {
    setNewTransaction(prev => ({
      ...prev,
      invoices: prev.invoices.filter((_, index) => index !== indexToRemove)
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setTransactions(prev => [...prev, newTransaction]);
    setNewTransaction({
      date: '',
      category: '',
      subCategory: '',
      amount: '',
      description: '',
      invoices: []
    });
    setShowForm(false);
  };

  return (
    <div className="p-4">
      <h2 className="text-2xl font-bold mb-4">Financials</h2>
      
      <button
        className="mb-4 bg-blue-500 text-white px-4 py-2 rounded flex items-center"
        onClick={() => setShowForm(!showForm)}
      >
        {showForm ? <ChevronUp className="mr-2" /> : <ChevronDown className="mr-2" />}
        {showForm ? 'Hide Form' : 'Add New Transaction'}
      </button>

      {showForm && (
        <form onSubmit={handleSubmit} className="mb-4 p-4 bg-gray-100 rounded">
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block mb-2">Date</label>
              <input
                type="date"
                name="date"
                value={newTransaction.date}
                onChange={handleInputChange}
                className="w-full p-2 border rounded"
                required
              />
            </div>
            <div>
              <label className="block mb-2">Category</label>
              <select
                name="category"
                value={newTransaction.category}
                onChange={handleInputChange}
                className="w-full p-2 border rounded"
                required
              >
                <option value="">Select category</option>
                {Object.keys(categories).map(cat => (
                  <option key={cat} value={cat}>{cat}</option>
                ))}
              </select>
            </div>
            <div>
              <label className="block mb-2">Sub-Category</label>
              <select
                name="subCategory"
                value={newTransaction.subCategory}
                onChange={handleInputChange}
                className="w-full p-2 border rounded"
                required
                disabled={!newTransaction.category}
              >
                <option value="">Select sub-category</option>
                {newTransaction.category && categories[newTransaction.category].map(subCat => (
                  <option key={subCat} value={subCat}>{subCat}</option>
                ))}
              </select>
            </div>
            <div>
              <label className="block mb-2">Amount</label>
              <input
                type="number"
                name="amount"
                value={newTransaction.amount}
                onChange={handleInputChange}
                className="w-full p-2 border rounded"
                required
              />
            </div>
          </div>
          <div className="mt-4">
            <label className="block mb-2">Description</label>
            <textarea
              name="description"
              value={newTransaction.description}
              onChange={handleInputChange}
              className="w-full p-2 border rounded"
              rows="3"
              required={newTransaction.subCategory === 'Mileage'}
            ></textarea>
          </div>
          {newTransaction.category === 'Expenses' && (
            <div className="mt-4">
              <label className="block mb-2">Upload Invoices (PDF or Image)</label>
              <div className="flex items-center">
                <input
                  type="file"
                  onChange={handleFileChange}
                  accept="application/pdf,image/*"
                  className="hidden"
                  id="invoice-upload"
                  multiple
                />
                <label
                  htmlFor="invoice-upload"
                  className="cursor-pointer bg-white border border-gray-300 rounded px-4 py-2 inline-flex items-center"
                >
                  <Paperclip className="mr-2" size={16} />
                  Choose Files
                </label>
              </div>
              {newTransaction.invoices.length > 0 && (
                <ul className="mt-2 space-y-2">
                  {newTransaction.invoices.map((file, index) => (
                    <li key={index} className="flex items-center bg-white p-2 rounded">
                      <span className="flex-grow truncate">{file.name}</span>
                      <button
                        type="button"
                        onClick={() => removeInvoice(index)}
                        className="ml-2 text-red-500 hover:text-red-700"
                      >
                        <X size={20} />
                      </button>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          )}
          <button type="submit" className="mt-4 bg-green-500 text-white px-4 py-2 rounded">
            Add Transaction
          </button>
        </form>
      )}

      <div className="overflow-x-auto">
        <table className="w-full border-collapse border border-gray-300">
          <thead>
            <tr className="bg-gray-100">
              <th className="border border-gray-300 p-2">Date</th>
              <th className="border border-gray-300 p-2">Category</th>
              <th className="border border-gray-300 p-2">Amount</th>
              <th className="border border-gray-300 p-2">Description</th>
              <th className="border border-gray-300 p-2">Invoices</th>
            </tr>
          </thead>
          <tbody>
            {transactions.map((transaction, index) => (
              <tr key={index} className={index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}>
                <td className="border border-gray-300 p-2">{transaction.date}</td>
                <td className="border border-gray-300 p-2">
                  {transaction.category} - {transaction.subCategory}
                </td>
                <td className="border border-gray-300 p-2">
                  ${parseFloat(transaction.amount).toFixed(2)}
                </td>
                <td className="border border-gray-300 p-2">{transaction.description}</td>
                <td className="border border-gray-300 p-2">
                  {transaction.invoices.map((file, fileIndex) => (
                    <div key={fileIndex}>
                      <a
                        href={URL.createObjectURL(file)}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500 hover:underline"
                      >
                        View Invoice {fileIndex + 1}
                      </a>
                    </div>
                  ))}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Financials;