import React from 'react';
import { Link } from 'react-router-dom';

const LandingPage = () => {
  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <header className="mb-8">
        <h1 className="text-4xl font-bold mb-2">Property Management Pro</h1>
        <p className="text-xl text-gray-600">Streamline your property management tasks and make informed decisions.</p>
      </header>

      <main>
        <div className="bg-gray-100 p-6 rounded-lg mb-8">
          <h2 className="text-2xl font-semibold mb-4">Powerful Tools for Property Managers</h2>
          <p className="text-gray-700 mb-4">
            Our suite of tools helps you manage properties efficiently, from rent collection to maintenance tracking. 
            Make data-driven decisions with our advanced analytics and reporting features.
          </p>
          <Link 
            to="/rent-vs-buy" 
            className="inline-block bg-blue-600 text-white font-bold py-2 px-4 rounded hover:bg-blue-700 transition duration-300"
          >
            Try Our Rent vs. Buy Calculator
          </Link>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="bg-white p-6 rounded-lg shadow">
            <h3 className="text-xl font-semibold mb-2">Rent Management</h3>
            <p className="text-gray-600">Easily track rent payments, set up automatic reminders, and manage late fees.</p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow">
            <h3 className="text-xl font-semibold mb-2">Maintenance Tracking</h3>
            <p className="text-gray-600">Log and track maintenance requests, schedule repairs, and communicate with tenants.</p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow">
            <h3 className="text-xl font-semibold mb-2">Financial Overview</h3>
            <p className="text-gray-600">Keep track of expenses like home insurance and property taxes in one centralized dashboard.</p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow">
            <h3 className="text-xl font-semibold mb-2">Tenant Communication</h3>
            <p className="text-gray-600">Streamline communication with tenants through our integrated messaging system.</p>
          </div>
        </div>
      </main>

      <footer className="mt-12 text-center text-gray-600">
        <p>&copy; 2023 Property Management Pro. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default LandingPage;