import React from 'react';
import { Link } from 'react-router-dom';
import { DollarSign, FileText, ArrowLeft, Home } from 'lucide-react';

const PropertySpecificSidebar = ({ property, onBackToProperties }) => {
  return (
    <>
      <li>
        <button onClick={onBackToProperties} className="flex items-center p-2 cursor-pointer w-full text-left">
          <ArrowLeft size={20} className="mr-2" />
          <span>All Properties</span>
        </button>
      </li>
      <li>
        <Link to={`/dashboard/property/${property.id}`} className="flex items-center p-2">
          <Home size={20} className="mr-2" />
          <span>Details</span>
        </Link>
      </li>
      <li>
        <Link to={`/dashboard/property/${property.id}/financials`} className="flex items-center p-2">
          <DollarSign size={20} className="mr-2" />
          <span>Financials</span>
        </Link>
      </li>
      <li>
        <Link to={`/dashboard/property/${property.id}/documents`} className="flex items-center p-2">
          <FileText size={20} className="mr-2" />
          <span>Documents</span>
        </Link>
      </li>
    </>
  );
};

export default PropertySpecificSidebar;