// src/components/InputSlider/InputSlider.js
import React from 'react';
import styles from './InputSlider.module.css';

const InputSlider = ({ label, value, setValue, min, max, step, unit }) => {
  return (
    <div className={styles.container}>
      <label className={styles.label}>{label}</label>
      <div className={styles.sliderContainer}>
        <input
          type="range"
          min={min}
          max={max}
          value={value}
          step={step}
          onChange={(e) => setValue(Number(e.target.value))}
          className={styles.slider}
        />
        <span className={styles.value}>{unit}{value.toLocaleString()}</span>
      </div>
    </div>
  );
}

export default InputSlider;