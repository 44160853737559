import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Landing from './pages/Landing';
import Dashboard from './layouts/Dashboard';
import RentVsBuy from './pages/RentVsBuy';
import Communication from './pages/Communication';
import Maintenance from './pages/Maintaince';
import DiscountedCashFlow from './pages/DiscountedCashFlow';
import PrivateRoute from './components/PrivateRoute';
import Login from './pages/Login';
import PropertyDetails from './pages/PropertyDetails';
import Documents from './components/Documents';
import Financials from './components/Financials';
import './App.css';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="/login" element={<Login />} />
      <Route path="/rent-vs-buy" element={<RentVsBuy />} />
      <Route path="/discounted-cash-flow" element={<DiscountedCashFlow />} />
      <Route 
        path="/dashboard" 
        element={
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        }
      >
        <Route path="communication" element={<Communication />} />
        <Route path="maintenance" element={<Maintenance />} />
        <Route path="property/:id" element={<PropertyDetails />} />
        <Route path="/dashboard/property/:id/documents" element={<Documents />} />
        <Route path="/dashboard/property/:id/financials" element={<Financials />} />
      </Route>
    </Routes>
  );
}

export default App;