// src/utils/calculations.js
export function calculateMortgage(principal, rate, term) {
    const monthlyRate = rate / 100 / 12;
    const numPayments = term * 12;
    return (principal * monthlyRate * Math.pow(1 + monthlyRate, numPayments)) / (Math.pow(1 + monthlyRate, numPayments) - 1);
  }
  
  export function analyzeCosts(params) {
    const {
      homePrice,
      downPayment,
      interestRate,
      loanTerm,
      propertyTaxRate,
      homeInsuranceRate,
      maintenanceRate,
      rentPrice,
      rentIncrease,
      homeAppreciation
    } = params;
  
    const downPaymentAmount = homePrice * (downPayment / 100);
    const loanAmount = homePrice - downPaymentAmount;
    const monthlyMortgage = calculateMortgage(loanAmount, interestRate, loanTerm);
    let currentMonthlyRent = rentPrice;
    const data = [];
  
    let cumulativeBuyingCost = downPaymentAmount;
    let cumulativeRentingCost = 0;
    let houseValue = homePrice;
  
    for (let year = 1; year <= loanTerm; year++) {
      const yearlyPropertyTax = houseValue * (propertyTaxRate / 100);
      const yearlyHomeInsurance = houseValue * (homeInsuranceRate / 100);
      const yearlyMaintenance = houseValue * (maintenanceRate / 100);
      
      const yearlyBuyingCost = (monthlyMortgage * 12) + yearlyPropertyTax + yearlyHomeInsurance + yearlyMaintenance;
      const yearlyRentingCost = currentMonthlyRent * 12;
      
      cumulativeBuyingCost += yearlyBuyingCost;
      cumulativeRentingCost += yearlyRentingCost;
      houseValue *= (1 + homeAppreciation / 100);
      currentMonthlyRent *= (1 + rentIncrease / 100);
  
      data.push({
        year,
        buyingCost: Math.round(yearlyBuyingCost),
        rentingCost: Math.round(yearlyRentingCost),
        cumulativeBuyingCost: Math.round(cumulativeBuyingCost - houseValue), // Net cost
        cumulativeRentingCost: Math.round(cumulativeRentingCost),
        houseValue: Math.round(houseValue),
      });
    }
  
    return data;
  }