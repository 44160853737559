// src/components/ExpenseTable/ExpenseTable.js
import React from 'react';
import styles from './ExpenseTable.module.css';

const ExpenseTable = ({ data }) => {
  return (
    <div className={styles.tableContainer}>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>Year</th>
            <th>Rent Cost</th>
            <th>Buying Cost</th>
            <th>Cumulative Rent</th>
            <th>Cumulative Buying (Net)</th>
            <th>House Value</th>
          </tr>
        </thead>
        <tbody>
          {data.map((year) => (
            <tr key={year.year}>
              <td>{year.year}</td>
              <td>${year.rentingCost.toLocaleString()}</td>
              <td>${year.buyingCost.toLocaleString()}</td>
              <td>${year.cumulativeRentingCost.toLocaleString()}</td>
              <td>${year.cumulativeBuyingCost.toLocaleString()}</td>
              <td>${year.houseValue.toLocaleString()}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default ExpenseTable;