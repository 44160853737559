import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyDNRzBor1ODjDNAtBRnq2Q9UfZ7TQuRbhk",
  authDomain: "blue-tile-roof-3a383.firebaseapp.com",
  databaseURL: "https://blue-tile-roof-3a383.firebaseio.com",
  projectId: "blue-tile-roof-3a383",
  storageBucket: "blue-tile-roof-3a383.appspot.com",
  messagingSenderId: "789060381427",
  appId: "1:789060381427:web:b0b0b2ec550b2041a7a586"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();