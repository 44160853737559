import React, { useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

function DiscountedCashFlow() {
  const [purchasePrice, setPurchasePrice] = useState(300000);
  const [mortgageRate, setMortgageRate] = useState(3.5);
  const [mortgageTerm, setMortgageTerm] = useState(30);
  const [homeInsurance, setHomeInsurance] = useState(1200);
  const [propertyTax, setPropertyTax] = useState(3000);
  const [repairExpenses, setRepairExpenses] = useState(3000);
  const [monthlyRent, setMonthlyRent] = useState(2000);
  const [rentIncrease, setRentIncrease] = useState(3);
  const [discountRate, setDiscountRate] = useState(7);

  const calculateMortgage = (principal, rate, term) => {
    const monthlyRate = rate / 100 / 12;
    const numPayments = term * 12;
    return (principal * monthlyRate * Math.pow(1 + monthlyRate, numPayments)) / (Math.pow(1 + monthlyRate, numPayments) - 1);
  };

  const analyzeCashFlow = () => {
    const monthlyMortgage = calculateMortgage(purchasePrice, mortgageRate, mortgageTerm);
    const data = [];
    let npv = -purchasePrice;

    for (let year = 1; year <= mortgageTerm; year++) {
      const yearlyRent = monthlyRent * 12 * Math.pow(1 + rentIncrease / 100, year - 1);
      const yearlyExpenses = (monthlyMortgage * 12) + homeInsurance + propertyTax + repairExpenses;
      const cashFlow = yearlyRent - yearlyExpenses;
      const discountFactor = Math.pow(1 + discountRate / 100, -year);
      npv += cashFlow * discountFactor;

      data.push({
        year,
        cashFlow: Math.round(cashFlow),
        cumulativeNPV: Math.round(npv),
      });
    }

    return data;
  };

  const data = analyzeCashFlow();

  return (
    <div className="prose max-w-none">
      <h1>Discounted Cash Flow Analysis</h1>
      <div className="grid grid-cols-2 gap-4">
        <div>
          <h2>Input Parameters</h2>
          <div className="form-control">
            <label className="label">
              <span className="label-text">Purchase Price</span>
            </label>
            <input type="number" value={purchasePrice} onChange={(e) => setPurchasePrice(Number(e.target.value))} className="input input-bordered" />
          </div>
          {/* Add more input fields for other parameters */}
        </div>
        <div>
          <h2>Analysis Results</h2>
          <LineChart width={600} height={300} data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="year" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="cashFlow" stroke="#8884d8" />
            <Line type="monotone" dataKey="cumulativeNPV" stroke="#82ca9d" />
          </LineChart>
        </div>
      </div>
      <div>
        <h2>Data Table</h2>
        <table className="table w-full">
          <thead>
            <tr>
              <th>Year</th>
              <th>Cash Flow</th>
              <th>Cumulative NPV</th>
            </tr>
          </thead>
          <tbody>
            {data.map((row) => (
              <tr key={row.year}>
                <td>{row.year}</td>
                <td>${row.cashFlow.toLocaleString()}</td>
                <td>${row.cumulativeNPV.toLocaleString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default DiscountedCashFlow;