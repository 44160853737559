// src/pages/RentVsBuy/RentVsBuy.js
import React from 'react';
import InputSlider from '../../components/InputSlider';
import ResultsSummary from '../../components/ResultsSummary';
import CostChart from '../../components/CostChart';
import ExpenseTable from '../../components/ExpenseTable';
import { useRentVsBuyCalculator } from './useRentVsBuyCalculator';
import styles from './RentVsBuy.module.css';

const RentVsBuy = () => {
  const {
    homePrice, setHomePrice,
    downPayment, setDownPayment,
    interestRate, setInterestRate,
    loanTerm, setLoanTerm,
    propertyTaxRate, setPropertyTaxRate,
    homeInsuranceRate, setHomeInsuranceRate,
    maintenanceRate, setMaintenanceRate,
    rentPrice, setRentPrice,
    rentIncrease, setRentIncrease,
    homeAppreciation, setHomeAppreciation,
    investmentReturn, setInvestmentReturn,
    data,
    summary,
    yAxisDomain
  } = useRentVsBuyCalculator();

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Rent vs Buy Analysis</h1>
      <div className={styles.grid}>
        <div>
          <h2 className={styles.subtitle}>Input Parameters</h2>
          <InputSlider label="Home Price" value={homePrice} setValue={setHomePrice} min={100000} max={1000000} step={10000} unit="$" />
          <InputSlider label="Down Payment (%)" value={downPayment} setValue={setDownPayment} min={0} max={100} step={1} unit="" />
          <InputSlider label="Interest Rate (%)" value={interestRate} setValue={setInterestRate} min={1} max={15} step={0.25} unit="" />
          <InputSlider label="Loan Term (years)" value={loanTerm} setValue={setLoanTerm} min={15} max={30} step={5} unit="" />
          <InputSlider label="Property Tax Rate (%)" value={propertyTaxRate} setValue={setPropertyTaxRate} min={0} max={5} step={0.1} unit="" />
          <InputSlider label="Home Insurance Rate (%)" value={homeInsuranceRate} setValue={setHomeInsuranceRate} min={0} max={2} step={0.1} unit="" />
          <InputSlider label="Maintenance Rate (%)" value={maintenanceRate} setValue={setMaintenanceRate} min={0} max={5} step={0.1} unit="" />
          <InputSlider label="Monthly Rent" value={rentPrice} setValue={setRentPrice} min={500} max={5000} step={100} unit="$" />
          <InputSlider label="Annual Rent Increase (%)" value={rentIncrease} setValue={setRentIncrease} min={0} max={10} step={0.5} unit="" />
          <InputSlider label="Home Appreciation (%)" value={homeAppreciation} setValue={setHomeAppreciation} min={0} max={10} step={0.5} unit="" />
          <InputSlider label="Investment Return (%)" value={investmentReturn} setValue={setInvestmentReturn} min={0} max={15} step={0.5} unit="" />
        </div>
        <div>
          <h2 className={styles.subtitle}>Analysis Results</h2>
          {data.length > 0 ? (
            <>
              <ResultsSummary summary={summary} loanTerm={loanTerm} />
              <CostChart data={data} yAxisDomain={yAxisDomain} />
              <h2 className={styles.subtitle}>Yearly Expense Breakdown</h2>
              <ExpenseTable data={data} />
            </>
          ) : (
            <p>Loading analysis results...</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default RentVsBuy;