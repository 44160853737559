// src/pages/RentVsBuy/useRentVsBuyCalculator.js
import { useState, useEffect } from 'react';
import { analyzeCosts } from '../../utils/calculations';

export function useRentVsBuyCalculator() {
  const [homePrice, setHomePrice] = useState(500000);
  const [downPayment, setDownPayment] = useState(20);
  const [interestRate, setInterestRate] = useState(7.25);
  const [loanTerm, setLoanTerm] = useState(30);
  const [propertyTaxRate, setPropertyTaxRate] = useState(1.2);
  const [homeInsuranceRate, setHomeInsuranceRate] = useState(0.5);
  const [maintenanceRate, setMaintenanceRate] = useState(1);
  const [rentPrice, setRentPrice] = useState(2000);
  const [rentIncrease, setRentIncrease] = useState(3);
  const [homeAppreciation, setHomeAppreciation] = useState(3);
  const [investmentReturn, setInvestmentReturn] = useState(7);
  const [data, setData] = useState([]);
  const [summary, setSummary] = useState({ rent: 0, buy: 0, savings: 0, isBetter: '' });
  const [yAxisDomain, setYAxisDomain] = useState([0, 1000000]);

  useEffect(() => {
    const newData = analyzeCosts({
      homePrice,
      downPayment,
      interestRate,
      loanTerm,
      propertyTaxRate,
      homeInsuranceRate,
      maintenanceRate,
      rentPrice,
      rentIncrease,
      homeAppreciation,
      investmentReturn
    });
    setData(newData);
    
    if (newData.length > 0) {
      const maxValue = Math.max(
        ...newData.map(d => Math.max(d.cumulativeBuyingCost, d.cumulativeRentingCost))
      );
      const roundedMax = Math.ceil(maxValue / 100000) * 100000;
      setYAxisDomain([0, roundedMax]);

      const lastYear = newData[newData.length - 1];
      const rentCost = lastYear.cumulativeRentingCost;
      const buyCost = lastYear.cumulativeBuyingCost;
      const savings = rentCost - buyCost;
      setSummary({
        rent: rentCost,
        buy: buyCost,
        savings: Math.abs(savings),
        isBetter: savings > 0 ? 'Buying' : 'Renting'
      });
    }
  }, [homePrice, downPayment, interestRate, loanTerm, propertyTaxRate, homeInsuranceRate, maintenanceRate, rentPrice, rentIncrease, homeAppreciation, investmentReturn]);

  return {
    homePrice, setHomePrice,
    downPayment, setDownPayment,
    interestRate, setInterestRate,
    loanTerm, setLoanTerm,
    propertyTaxRate, setPropertyTaxRate,
    homeInsuranceRate, setHomeInsuranceRate,
    maintenanceRate, setMaintenanceRate,
    rentPrice, setRentPrice,
    rentIncrease, setRentIncrease,
    homeAppreciation, setHomeAppreciation,
    investmentReturn, setInvestmentReturn,
    data,
    summary,
    yAxisDomain
  };
}